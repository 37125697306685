import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Seo from "../components/Seo";
import Layout from "../components/Layout";
import { HTMLContent } from "../components/Content";
import { Buffer } from "buffer";
import FullWidthImage from "../components/FullWidthImage";
import DisplayTitleWithImage from "../components/DisplayTitleWithImage";
import DisplayContent from "../components/DisplayContent";

// eslint-disable-next-line
export const ContactPageTemplate = ({
  title,
  subtitle,
  footer,
  images,
  videos,
  breadcrumbs,
  options,
  body,
  helmet,
  previewContent,
  layout = {},
  contact,
  header,
  config,
}) => {
  let templateName = "contact-page",
    heroImage,
    smallImage,
    footerImage;

  if (!layout) layout = [];

  if (images) {
    if (images.hero) {
      heroImage = getImage(images.hero) || images.hero;
    }
    if (images.footer) {
      footerImage = getImage(images.footer) || images.footer;
    }
    if (images.smallImage) smallImage = images.smallImage;
  }

  const showTitle = options.showTitle;

  let showDisplayContentHeader = true;
  if (
    !options.showDisplayContentHeader &&
    options.showDisplayContentHeader !== undefined &&
    options.showDisplayContentHeader !== null
  ) {
    showDisplayContentHeader = false;
  }
  let id;
  if (contact.id) {
    id = Buffer.from(contact.id).toString("base64");
  }

  // console.log(contact);
  // contact.siteKey is used to give the impression of a h-captcha

  return (
    <div>
      {helmet || null}

      {heroImage ? (
        <FullWidthImage
          img={heroImage}
          title={title}
          subheading={subtitle}
          imgPosition="center"
          showTitle={showTitle}
        />
      ) : null}

      <section className={`section section--gradient ${templateName}`}>
        <div className="container">
          <div className="content">
            <DisplayTitleWithImage
              smallImage={smallImage}
              title={title}
              subtitle={subtitle}
              showDisplayContentHeader={showDisplayContentHeader}
              breadcrumbs={breadcrumbs}
            />

            {previewContent ? (
              <>
                <form>
                  <div className="content content___contact-page">
                    {previewContent}
                  </div>
                </form>
              </>
            ) : (
              <>
                {contact && contact.id && contact.redirect ? (
                  <>
                    <DisplayContent
                      id={templateName}
                      previewContent={previewContent}
                      body={
                        <>
                          <form
                            action={`https://submit-form.com/${contact.id}`}
                            method="POST"
                            id="contact"
                          >
                            <input
                              type="hidden"
                              name="_redirect"
                              value={`${config.url}${contact.redirect}`}
                            />
                            <span className="is-hidden">
                              {contact.id && id ? (
                                <input
                                  type="text"
                                  name="formId"
                                  value={id}
                                  tabIndex="-1"
                                  readOnly
                                />
                              ) : (
                                <></>
                              )}
                              {config.url ? (
                                <input
                                  type="text"
                                  name="hostname"
                                  value={config.url}
                                  tabIndex="-1"
                                  readOnly
                                />
                              ) : (
                                <></>
                              )}
                              <input
                                type="text"
                                name="redirectSuccess"
                                value={contact.redirect}
                                tabIndex="-1"
                              />
                              <input
                                type="text"
                                name="redirectError"
                                value={contact.error}
                                tabIndex="-1"
                                readOnly
                              />
                              {body.indexOf("prefix") === -1 ? (
                                <div className="content">
                                  <input
                                    className="input"
                                    type="text"
                                    name="prefix"
                                    tabIndex="-1"
                                  />
                                </div>
                              ) : (
                                <></>
                              )}
                            </span>
                            <HTMLContent
                              className="content content___contact-page"
                              content={body}
                            />
                            {body.indexOf("h-captcha") === -1 &&
                            contact.siteKey ? (
                              <div
                                className="h-captcha"
                                data-sitekey={contact.siteKey}
                              ></div>
                            ) : (
                              <></>
                            )}
                          </form>
                        </>
                      }
                      layout={layout}
                      element={false}
                    />
                  </>
                ) : (
                  <HTMLContent
                    className="content content___contact-page"
                    content={body}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </section>

      {footerImage ? (
        <FullWidthImage
          img={footerImage}
          subheading={footer}
          imgPosition="center"
          className="footer-image-text"
          showTitle={true}
        />
      ) : null}
    </div>
  );
};

ContactPageTemplate.propTypes = {
  helmet: PropTypes.object,
  title: PropTypes.string.isRequired,
  name: PropTypes.string,
  subtitle: PropTypes.string,
  footer: PropTypes.string,
  images: PropTypes.shape({
    hero: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    smallImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    smallImageUrl: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    footer: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  author: PropTypes.string,
  creationDate: PropTypes.string,
  sortDate: PropTypes.string,
  flags: PropTypes.shape({
    published: PropTypes.bool,
    featured: PropTypes.bool,
  }),
  options: PropTypes.shape({
    showTitle: PropTypes.bool,
    showSummary: PropTypes.bool,
    showTags: PropTypes.bool,
    showComments: PropTypes.bool,
    showAuthorSummary: PropTypes.bool,
    showPrevNext: PropTypes.bool,
    showPopular: PropTypes.bool,
    showRelated: PropTypes.bool,
  }),
  breadcrumbs: PropTypes.shape({
    parent: PropTypes.string,
    parentSlug: PropTypes.string,
    grandparent: PropTypes.string,
    grandparentSlug: PropTypes.string,
  }),
  body: PropTypes.string,
  summary: PropTypes.string,
  contact: PropTypes.shape({
    id: PropTypes.string,
    redirect: PropTypes.string,
  }),
};

const ContactPage = ({ data }) => {
  const { markdownRemark: post } = data;

  let { site, config } = data;
  let header = config;

  let contact = config.frontmatter.contact;
  config = { ...config.frontmatter.site, ...config.frontmatter.contact };
  site = site.siteMetadata.property;

  let siteUrl = site.url;
  let siteName = site.name;
  let sitePhone = "";
  let siteEmail = site.email;

  if (config.url) siteUrl = config.url;
  if (config.name) siteName = config.name;
  if (config.phone) sitePhone = config.phone;

  let replacements = [];
  replacements["{siteurl}"] = siteUrl;
  replacements["{siteName}"] = siteName;
  replacements["{sitePhone}"] = sitePhone;
  replacements["{siteEmail}"] = siteEmail;

  if (config.location && config.location.length) {
    if (config.location[0] && config.location[0].address)
      replacements["{address_1}"] = config.location[0].address;
    if (config.location[1] && config.location[1].address)
      replacements["{address_2}"] = config.location[1].address;
    if (config.location[2] && config.location[2].address)
      replacements["{address_3}"] = config.location[2].address;
  }

  let html = post.html;

  if (replacements) {
    let keys = Object.keys(replacements);
    if (keys.length) {
      keys.forEach((key) => {
        html = html.replace(new RegExp(key, "g"), replacements[key]);
      });
    }
  }

  let imageSrc =
    post.frontmatter.images.hero.childImageSharp.gatsbyImageData.images.fallback
      .src;
  if (!post.frontmatter.titles) post.frontmatter.titles = {};

  return (
    <Layout>
      <ContactPageTemplate
        title={post.frontmatter.title}
        subtitle={post.frontmatter.titles.subtitle}
        footer={post.frontmatter.titles.footer}
        images={post.frontmatter.images}
        author={post.frontmatter.author}
        creationDate={post.frontmatter.creationDate}
        options={post.frontmatter.options}
        breadcrumbs={post.frontmatter.breadcrumbs}
        body={html}
        helmet={
          <>
            <Seo
              title={post.frontmatter.title}
              summary={post.frontmatter.summary}
              image={imageSrc}
              article={false}
            />
          </>
        }
        contact={contact}
        config={config}
        header={header}
      />
    </Layout>
  );
};

ContactPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ContactPage;

export const contactPageQuery = graphql`
  query ContactPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        titles {
          subtitle
          footer
        }
        images {
          hero {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
          footer {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
          smallImage {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
          smallImageUrl
        }
        options {
          showTitlesSwapped
          showDisplayContentHeader
          showTitle
        }
        breadcrumbs {
          parent
          parentSlug
          grandparent
          grandparentSlug
        }
        summary
      }
    }
    site {
      siteMetadata {
        property {
          url
          name: siteName
        }
      }
    }
    config: markdownRemark(
      frontmatter: {
        advanced: {
          configKey: { eq: "site" }
          templateKey: { eq: "configuration" }
        }
      }
    ) {
      frontmatter {
        site {
          url
          name
          phone
          location {
            address
          }
          email
        }
        contact {
          id
          redirect
          error
        }
      }
    }
  }
`;
